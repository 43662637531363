<template>
  <div class="tw-flex tw-px-3 lg:tw-px-6 tw-py-4 tw-w-full tw-flex-col">

  <div class="below ">
    <div class="tw-flex tw-w-full tw-flex-col">
      <h5 class="amount-header tw-pb-5">Payment Summary</h5>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-pb-5">
        <h5 class="amount-desc">Total Price</h5>
        <h5 class="amount-header">&#8358;{{formatNumber(oneWayTotalPrice)}}</h5>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-pb-5">
        <h5 class="amount-desc">Discount</h5>
        <h5 class="amount-header">&#8358;{{formatNumber(oneWayTotalDiscount)}}</h5>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-pb-5">
        <h5 class="amount-desc">Voucher</h5>
        <h5 class="amount-header">&#8358;{{formatNumber(oneWayTotalVoucher)}}</h5>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-pb-5">
        <v-text-field placeholder="Have any coupon or voucher" flat color="white" hide-details solo v-model="voucherCode">
          <template v-slot:append>
            <v-btn class="voucher-btn" @click="applyVoucherCode" :loading="voucherCodeLoading">Apply</v-btn>
          </template>
        </v-text-field>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-py-5">
        <h5 class="amount-desc">Grand Total</h5>
        <h5 class="amount-header">&#8358;{{formatNumber(oneWayTotalAmount)}}</h5>
      </div>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col" v-for="(passenger,index) in tripAndTravellerDetails.seats" :key="index">
      <p :style="{color: companyData.companyColor}" class="header my-4">Passenger <span v-if="index >0">{{index+1}}</span></p>
      <div class="tw-flex tw-w-full tw-flex-col">
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user" :color="companyData.companyColor"  />
          </div>
          <div  class="content">{{passenger.travellerDetail.firstName +" "+ passenger.travellerDetail.lastName}} </div>
        </div>
<!--        <div class="tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-gender" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content">{{passenger.travellerDetail.gender}} </div>-->
<!--        </div>-->
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-phone-number"  :color="companyData.companyColor" />
          </div>
          <div  class="content">{{passenger.travellerDetail.phoneNumber}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-email" :color="companyData.companyColor" />
          </div>
          <div  class="content">{{passenger.travellerDetail.email}} </div>
        </div>
<!--        <div class="tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-date-of-birth" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content" v-if="passenger.travellerDetail.dateOfBirth">{{getDateOfBirth(passenger.travellerDetail.dateOfBirth)}} </div>-->
<!--        </div>-->
<!--        <div class=" tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-address" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content text-capitalize" v-if="passenger.travellerDetail.address">{{passenger.travellerDetail.address.houseNumber+" "+-->
<!--          passenger.travellerDetail.address.streetName+ ","+-->
<!--          passenger.travellerDetail.address.city+" "+passenger.travellerDetail.address.state+" "+-->
<!--          passenger.travellerDetail.address.country}} </div>-->
<!--        </div>-->
      </div>
    </div>

  </div>
<!--    <v-checkbox >-->
<!--      <template #label>-->
<!--        <h6> Add insurance plan to my total price </h6>-->
<!--      </template>-->
<!--    </v-checkbox>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Icons from "../Icons.vue";
import dayjs from "dayjs";
import {applyVoucher} from "@/services/api/APIService";
export default {
    name:"CheckoutPassengerSummary",
  components:{Icons},
data(){
    return{
      voucherCodeLoading:false,
      voucherCode:null,
    }
},
  methods:{
    getDateOfBirth(dateOfBirth){
      return dayjs(dateOfBirth).format("ll")
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
    },
    async applyVoucherCode() {
      if (this.voucherCode) {
        this.voucherCodeLoading = true
        let data = {}
        data.code = this.voucherCode
        data.tripId = this.userSelectedTrip.id
        applyVoucher(data).then(res => {
          this.voucherCodeLoading = false
          res.data.voucher.code = this.voucherCode
          this.$store.dispatch("seatsSelection/setTotalVoucher", res.data.voucher)
        }).catch(err =>{
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
        }).finally(() => this.voucherCodeLoading = false)
      } else {
        this.$displaySnackbar({
          message: "Enter a valid Voucher Code",
          success: false
        })
      }
    }
  },
  computed:{
      ...mapGetters("tripAndTravellerDetails", ["tripAndTravellerDetails"]),
    ...mapGetters("companyProfile", ["companyData"]),
    ...mapGetters("seatsSelection",["oneWayTotalVoucher","oneWayTotalDiscount","oneWayTotalPrice","userSelectedTrip","oneWayTotalAmount"])
  }

}
</script>

<style lang="scss" scoped>
.below {
   width: 100%;
   border: 2px dashed #000000;
   padding: 40px;
   background: #F2F2F4;
  @media screen and (max-width: 820px) {
    padding: 20px;
  }
  }

.header{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;

}
.content{
    
font-family: 'Inter',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: rgba(0, 17, 39, 0.73);
  padding: 0.5rem 0;
}

.amount-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
  //padding: 0.5rem 0;
}
.amount-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
  //padding: 0.5rem 0;
}

.voucher-btn{
  font-family: Inter,sans-serif;
  font-size: 16px !important;
  font-weight: normal !important;
  background: var(--black) !important;
  color: var(--white);
  text-transform: capitalize;
}
.voucher-btn-disabled{
  font-family: Inter,sans-serif;
  font-size: 16px !important;
  font-weight: normal !important;
  background: var(--brown) !important;
  color: var(--white);
  text-transform: capitalize;
}
</style>